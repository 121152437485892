import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import semi_automatic from "../../public/static/images/products/semi_automatic.jpg"

function SemiAutomatic() {

  const prodDetail = {
    "name": "Semi Automatic Anti Smog Gun",
    "detail": {
      "about" : ["We are the leading manufacturer and supplier of Anti Smog Gun in Manual, Semi-Automatic and Automatic mode in different throwing capacities."],
      "advantages": ["Smog Gun vehicle is a specialized tool for disinfection of epidemic- containment and potentially contaminated areas (epidemic areas); it has become an important technical means to stop the spread of virus. The use of disinfection vehicle can greatly improve the disinfection and virus killing effect in a large area, greatly improve the disinfection efficiency and evenly disinfect, effectively blocking the virus transmission."]
    },
    "tech_data": {
      "column1": [
        "Spraying Distance",
        "Fan Diameter",
        "Fan Blade",
        "Horizontal Rotating Angle",
        "Elevation Angle",
        "Water Row",
        "Water Pump Pressure",
        "Main Motor",
        "Water Pump Motor",
        "Spraying Water RIng",
        "Material of Water Ring",
        "Pump",
        "Type"
      ],
      "column2": [
        "25-30 Mtrs.",
        "475mm",
        "8 pcs",
        "350°",
        "10°~60°",
        "12-36 LPM",
        "1.5-3 Mpa",
        "3Kw",
        "2.5 Kw",
        "Single Rings",
        "Stainless Steel 304",
        "Triplex Piston Pump",
        "Automatic / Semi Automatic / Manual"
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={semi_automatic} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default SemiAutomatic
